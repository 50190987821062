// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect, useState, useRef } from "react";
import Gauge from "../../components/Gauge";
import { jwtDecode } from "jwt-decode";
import PdfComponent from "../../components/PdfComponent";
import BreadHeader from "../../components/breadheader";
import MainDashboard from "../../components/dashboards/main_dashboard";
import TopGauge from "../../components/topgauge";
import AutocompleteInput from "../../components/AutocompleteInput";
import MapComponent from "../../components/MapComponent";
import { format } from "date-fns";
import generatePDF from "react-to-pdf";

import axiosPrivate, {
  axiosEco,
  axiosPrivateOid,
  axiosPrivateRiskScore,
} from "../../api/axiosPrivate";
import { ClimateScores, RiskData } from "../../data/riskData";
import { isInProtectedArea } from "../../components/utils";
import Swal from "sweetalert2";
import ButtonLoading from "../../components/ButtonLoading";
import html2pdf from "html2pdf.js";
import { scoreTooltipData } from "../../data/requestData";
import NewsFeed from "../../components/NewsFeed";
import cropData from "../../data/cropData";
import {
  AuthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser";
import { loginRequest, msalConfig, protectedResources } from "../../authConfig";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { msalInstance } from "../../main";

import { API_BASE_URL, API_BASE_URL_NEW, FE_APP_ID } from "../../constants";
import GoogleMapComponent from "../../components/GoogleMapComponent";
import { useLoadScript } from "@react-google-maps/api";
import { changeKeys, pdfOptions } from "../../utils";
import GradientBarChart from "../../components/GradientBarchart";
import IdleGauge from "../../components/IdleGauge";
import Places from "../../components/PlacesAutocomplete";
import ProgressBarHome from "../../components/ProgressBarHome";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../context/Language";
import { usePayment } from "../../context/Payment";
import { useNavigate } from "react-router-dom";
export interface MapCrop {
  isMarkerPlaced: boolean;
  isLocationProtected: boolean;
  selectedCrop: string;
  locationName: string;
  loanPeriod: string;
  mapLocation: MapLocation;
}

export interface MapLocation {
  lat: number | null;
  lng: number | null;
}

const getFormattedFutureDate = (numberOfMonths: number): string => {
  const today = new Date();
  today.setMonth(today.getMonth() + numberOfMonths);
  return format(today, "yyyy-MM-dd"); // Format the date as "yyyy-MM-dd"
};

const getDateToday = (): string => {
  const today = new Date();
  return format(today, "yyyy-MM-dd"); // Format the date as "yyyy-MM-dd"
};

const getFormattedTodayDate = (selectedDate: Date): string => {
  return format(selectedDate, "yyyy-MM-dd"); // Format the date as "yyyy-MM-dd"
};
const libraries = ["places"];
const googleMapsApiKey = import.meta.env.VITE_MAP_API_KEY;
const Home = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey,
    // @ts-ignore
    libraries: libraries,
  });
  const userNameEmail = localStorage.getItem("mail");
  const role = localStorage.getItem("empType");
  console.log(role, "role from home ");
  const displayName = localStorage.getItem("displayName");
  let firstName: string = "";
  if (displayName) {
    firstName = displayName.split(" ")[0];
  }
  console.log(firstName, "firstName from home ");
  const accountIdentifiers = {
    username: "example-username",
  };
  const request = {
    loginHint: "example-username",
    scopes: {
      user_impersonation: [
        "https://adaptaearth.onmicrosoft.com/3cb30d24-6c3c-4477-81d7-9efc861ad2d6/user_impersonation",
      ], //['https://fabrikamb2c.onmicrosoft.com/TodoList/ToDoList.Read'],
      // user_impersonation: ["https://adaptaearth.onmicrosoft.com/3cb30d24-6c3c-4477-81d7-9efc861ad2d6/user_impersonation"], //['https://fabrikamb2c.onmicrosoft.com/TodoList/ToDoList.ReadWrite'],
    },
  };
  const { t, i18n } = useTranslation("home");
  const { instance, inProgress, accounts } = useMsal();
  const [apiData, setApiData] = useState(null);
  const [allCrops, setAllCrops] = useState(null);
  const { selectedLanguage } = useLanguage();
  const [topThreeRisks, setTopThreeRisks] = useState([]);
  const [topThreeIndicis, setTopThreeIndicis] = useState([]);
  const [geoJson, setGeoJson] = useState({});
  const [source, setSource] = useState("");
  const URL_LIST_CROPS = `v1/eco-crop?page=1&per_page=1000`;
  const waterSource = [
    { name: t("Rainfed"), value: "rainfed" },
    { name: t("Rainfed & Irrigation"), value: "rainfed_irrigation" },
  ];

  async function fetchCrops() {
    setAllCrops([]);
    axiosEco<any>({ method: "GET", url: URL_LIST_CROPS })
      .then((data) => {
        console.log("dataCrop", data?.data?.data);
        const sortedData = data?.data?.data.sort((a, b) => a.localeCompare(b));
        // console.log(sortedCrops, "sort");

        const cropDate = sortedData.map((item) => {
          return {
            name: t(item),
            value: item,
          };
        });

        // const mappedCrops = cropDate.reduce((acc, crop) => {
        //   acc[crop] = t(crop); // No need for special handling; t() works with multi-word strings too
        //   return acc;
        // }, {});
        console.log(cropDate, "ss");

        setAllCrops(cropDate);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: t("Error"),
          text: t("Something went wrong"),
        });
        console.error("API Error:", error);
      });
  }

  useEffect(() => {
    fetchCrops();
  }, [selectedLanguage]);

  const options = cropData;

  const initialFormValues = {
    crop: "",
    latitude: null,
    longitude: null,
    startDate: "",
    endDate: "",
  };

  const initialClimateScores: ClimateScores = {
    rainfall_risk: 0,
    temperature_risk: 0,
    drought_score: 0,
    rainfall_score: 0,
    temperature_score: 0,
    composite_climate_score: 0,
    drought_risk: 0,
    composite_climate_risk: 0,
  };

  const climate_indices = [t("Drought"), t("Precipitation"), t("Temperature")];

  const water_indices = [
    t("Groundwater"),
    t("Water Erosion"),
    t("Water Stress"),
  ];

  const soil_indices = [
    t("Top Soil Fertility"),
    t("Soil pH"),
    t("Nutrient capacity"),
  ];

  const [climateScores, setClimateScores] =
    useState<ClimateScores>(initialClimateScores);

  const [riskData, setRiskData] = useState<RiskData>();
  const [locationScoreId, setLocationScoreId] = useState<"">();
  const [isLoading, setIsLoading] = useState(false);
  const [isComputed, setIsComputed] = useState(false);
  const [isLocationProtected, setIsLocationProtected] = useState(false);
  const { credits, setCredits } = usePayment();
  const [selectedCrop, setSelectedCrop] = useState("");
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [formValues, setFormValues] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const elementRef = useRef(null);
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const [inputValueAuto, setInputValueAuto] = useState<string>("");
  const [mapCrop, setMapCrop] = useState<MapCrop>({
    isMarkerPlaced: true,
    isLocationProtected: false,
    selectedCrop: "",
    locationName: "",
    loanPeriod: "",
    mapLocation: {
      lat: 0, // Initial latitude value
      lng: 0, // Initial longitude value
    },
  });

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [toMonths, setToMonths] = useState(1);

  useEffect(() => {
    // Set initial values for "from date" and "to date" when the component is mounted

    const today = new Date();
    handleFromDateChange(today);
    handleToDateChange();
  }, []);
  const handleChangeMonth = (event) => {
    let months = parseInt(event.target.value);
    if (months < 1) {
      months = 1;
    }
    setToMonths(months);
    const tDate = getFormattedFutureDate(months);
    const fDate = getDateToday;
    setFromDate(fDate);
    setToDate(tDate);
    setFormValues({
      ...formValues,
      startDate: fromDate,
      endDate: toDate,
    });
    const latitude = selectedLocation?.lat;
    const longitude = selectedLocation?.lng;

    // Update the form with the selected crop name
    setFormValues({
      ...formValues,
      crop: selectedCrop,
      startDate: fromDate,
      endDate: toDate,
      latitude: latitude,
      longitude: longitude,
    });
  };

  const handleBlur = () => {
    if (toMonths < 1 || isNaN(toMonths)) {
      setToMonths(1);
      const tDate = getFormattedFutureDate(1);
      const fDate = getDateToday;
      setFromDate(fDate);
      setToDate(tDate);
      setFormValues({
        ...formValues,
        startDate: fromDate,
        endDate: toDate,
      });
    }
  };

  const handleFromDateChange = (selectedDate: Date) => {
    const fDate = getFormattedTodayDate(selectedDate);
    setFromDate(fDate);
    setFormValues({
      ...formValues,
      startDate: fromDate,
    });
  };

  const handleToDateChange = () => {
    const fDate = getFormattedFutureDate(1);
    setToDate(fDate);
    setFormValues({
      ...formValues,
      endDate: toDate,
    });
  };

  const [selectedLocation, setSelectedLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const handleLocationSelect = (
    location: { lat: number; lng: number; address: string } | null,
    address: string
  ) => {
    setSelectedLocation(location);
    setFormValues({
      ...formValues,
      crop: selectedCrop,
      startDate: fromDate,
      endDate: toDate,
      longitude: location.lng,
      latitude: location.lat,
      locationName: address,
    });
    isInProtectedArea([location.lng, location.lat]).then((result) => {
      console.log(result, "result");

      setGeoJson(result[1]);

      if (!result[0] === true) {
        setIsLocationProtected(true);
        Swal.fire({
          icon: t("Warning"),
          title: `${address} is  a protected area .`,
          text: t("Select an area that is not protected to proceed"),
        });
      } else {
        setIsLocationProtected(false);
      }
    });
  };

  const handleSelect = (selected: Option | null) => {
    setSelectedOption(selected);
  };
  const handlePrint = () => {
    const printContents = document.getElementById("printablediv")?.innerHTML;
    const originalContents = document.body.innerHTML;

    if (printContents) {
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    }
  };
  const getTargetElement = () => document.getElementById("printablediv");

  const handleDownloadPDF = () => {
    // const modalContent = document.getElementById("printablediv");
    // console.log("generatePDF:modalContent: ", modalContent);
    // html2pdf()
    //   .set({
    //     pagebreak: { mode: "avoid-all" },
    //   })
    //   .from(modalContent)
    //   .save("modal_content.pdf");
    console.log("pdf run");
    generatePDF(getTargetElement, pdfOptions);
  };
  const isFormValid = (): boolean => {
    if (formValues.latitude == null || formValues.longitude == null) {
      Swal.fire({
        icon: "error",
        title: t("Location Required"),
        text: t("Please provide latitude and longitude"),
      });
      return false;
    } else if (formValues.crop == null || formValues.crop == "") {
      Swal.fire({
        icon: "error",
        title: t("Crop Required"),
        text: t("Please select a crop"),
      });
      return false;
    } else if (formValues.startDate == null || formValues.endDate == "") {
      Swal.fire({
        icon: "error",
        title: t("Loan Perid Required"),
        text: t("Please provide number of months for the Loan"),
      });
      return false;
    } else if (isLocationProtected) {
      Swal.fire({
        icon: "error",
        title: t("STOP."),
        text: t("You have selected a Protected Area"),
      });
      return false;
    }

    return true;
  };

  const handleMarkerPlaced = ({ lat, lng }) => {
    setSelectedLocation({ lat, lng });
    setFormValues({
      ...formValues,
      crop: selectedCrop,
      startDate: fromDate,
      endDate: toDate,
      latitude: lat,
      longitude: lng,
    });
    setMapCrop((prevState) => ({
      ...prevState,
      isMarkerPlaced: true,
      isLocationProtected: false,
      selectedCrop: selectedCrop,
      locationName: "",
      startDate: fromDate,
      endDate: toDate,
      mapLocation: {
        lat: lat, // New latitude value
        lng: lng, // New longitude value
      },
    }));
    isInProtectedArea([lng, lat]).then((result) => {
      console.log(result, "res res");
      setGeoJson(result[1]);
      if (!result[0] === true) {
        setIsLocationProtected(true);
        Swal.fire({
          icon: "Warning",
          title: t("STOP."),
          text: t("You have selected a Protected Area"),
        });
      } else {
        setIsLocationProtected(false);
      }
    });
  };

  console.log(geoJson, "geoJson");
  const pickColor = (score) => {
    if (score >= 0 && score <= 30) {
      return "#6ebd5f";
    } else if (score > 30 && score <= 40) {
      return "#b0bf58";
    } else if (score > 40 && score <= 50) {
      return "#f6ca5a";
    } else if (score > 50 && score <= 60) {
      return "#fac25d";
    } else if (score > 60 && score <= 70) {
      return "#fa9359";
    } else if (score > 70 && score <= 100) {
      return "#fc5874";
    } else {
      return "#e5e7eb";
    }
  };
  const handleMarkerClear = () => {
    setMapCrop((prevState) => ({
      ...prevState,
      isMarkerPlaced: false,
      isLocationProtected: false,
      selectedCrop: "",
      locationName: "",
      startDate: "",
      endDate: "",
      mapLocation: {
        lat: null, // New latitude value
        lng: null, // New longitude value
      },
    }));
  };

  const handleClearForm = async () => {
    Swal.fire({
      title: t("Do you want to save the changes?"),
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonColor: "#22c55e",
      denyButtonColor: "#3b82f6",
      cancelButtonColor: "red",
      confirmButtonText: "Save & Clear",
      denyButtonText: `Save`,
      cancelButtonText: "Clear",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setSelectedCrop("");
        setFormValues(initialFormValues);
        // setRiskData(Risk);
        setClimateScores(initialClimateScores);
        setIsComputed(false);
        Swal.fire("Saved and Reset", "", "success");
        // window.location.reload();
      } else if (result.isDismissed) {
        setSelectedCrop("");
        setFormValues(initialFormValues);
        // setRiskData(Risk);
        setClimateScores(initialClimateScores);
        setIsComputed(false);
        // Swal.fire("Reset", "", "success");
        // window.location.reload();
      } else if (result.isDenied) {
        setSelectedCrop("");
        // setFormValues(initialFormValues);
        // setRiskData(Risk);
        setClimateScores(initialClimateScores);
        setIsComputed(false);
        Swal.fire("Changes saved", "", "info");
        // window.location.reload();
      }
    });
  };

  const handleCropSelect = (event) => {
    const selectedCropName = event.target.value;
    setSelectedCrop(selectedCropName);
    const latitude = selectedLocation?.lat;
    const longitude = selectedLocation?.lng;

    // Update the form with the selected crop name
    setFormValues({
      ...formValues,
      crop: selectedCropName,
      startDate: fromDate,
      endDate: toDate,
      latitude: latitude,
      longitude: longitude,
    });
  };

  const handleReportClick = () => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  };
  console.log("source", source);
  const onSubmit = async () => {
    // htmlToImageConvert();
    if (!isFormValid()) {
      return;
    }

    if (role === "single_user") {
      if (credits < 1) {
        Swal.fire({
          icon: "error",
          title: t("Your Credits Has Expired"),
          text: t("Please top up to keep using searches"),
          showCancelButton: true, // This will show the "OK" button
          cancelButtonText: t("Okay"),
          confirmButtonText: t("Buy More"), // This is for the "Buy More" button
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            // Handle "Buy More" action here
            navigate("/currency");
          }
        });
        return;
      }

      if (credits < 6) {
        Swal.fire({
          icon: "error",
          title: t("Your Credits Has Expired"),
          text: t("Please top up to keep using searches"),
          showCancelButton: true, // This will show the "OK" button
          cancelButtonText: t("Okay"),
          confirmButtonText: t("Buy More"), // This is for the "Buy More" button
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            // Handle "Buy More" action here
            navigate("/currency");
          }
        });
      }
    }

    const latitude = selectedLocation?.lat;

    const longitude = selectedLocation?.lng;
    const address = selectedLocation?.address;
    console.log("selected", selectedLocation);
    // Update the form with the selected crop name
    // setFormValues((prevState) => ({
    //   ...prevState,
    //   crop: selectedCrop,
    //   startDate: fromDate,
    //   locationName: address,
    //   endDate: toDate,
    //   latitude: latitude,
    //   longitude: longitude,
    // }));
    const body = {
      ...formValues,
      latitude: latitude,
      longitude: longitude,
      water_source: source,
    };
    // alert(formValues.latitude);

    const RISK_URL = `/v1/location_scores/get_score`;
    setIsLoading(true);
    await axiosPrivateRiskScore<any>({
      method: "POST",
      url: RISK_URL,
      data: JSON.stringify(body),
    })
      .then((data) => {
        setCredits((s) => s - 1);
        if (
          data === null ||
          data === undefined ||
          Object.keys(data).length === 0
        ) {
          // Handle empty data
          Swal.fire({
            icon: "warning",
            title: t("Area Data Not Available"),
            text: t("Error retriving data From Area"),
          });
        } else {
          // if (data?.data?.soil_scores.rank === "None") {
          //   console.log("soil data not");
          // }
          scrollToDiv();
          let calculateResult: RiskData = data?.data;

          console.log(calculateResult, "calculated ");
          const specificScores = [
            "water_scores",
            "soil_scores",
            "climate_scores",
          ];
          const result = [];

          specificScores.forEach((scoreKey) => {
            if (calculateResult?.total_scores.hasOwnProperty(scoreKey)) {
              result.push(calculateResult.total_scores[scoreKey]);
            }
          });
          const keysArray = [
            "ground_water_risk",
            "location_aquaduct_risk",
            "rainfall_erosivity_risk",
            "soil_organic_carbon_risk",
            "soil_ph_risk",
            "cation_exchange_capacity_risk",
            "drought_risk",
            "precipitation_risk",
            "temperature_risk",
          ];
          const resultArray = [];

          result?.forEach((obj) => {
            keysArray.forEach((key) => {
              if (obj.hasOwnProperty(key)) {
                resultArray.push({ [key]: obj[key] });
              }
            });
          });

          // Sort the array in descending order based on the values
          resultArray.sort((a, b) => {
            const aValue = Object.values(a)[0];
            const bValue = Object.values(b)[0];
            return bValue - aValue;
          });

          console.log(resultArray, "result array");

          const keyMapping = {
            cation_exchange_capacity_risk: t("Nutrient capacity"),
            rainfall_erosivity_risk: t("Water Erosion"),
            soil_organic_carbon_risk: t("Top Soil Fertility"),
            temperature_risk: t("Temperature"),
            drought_risk: t("Drought"),
            ground_water_risk: t("GroundWater"),
            location_aquaduct_risk: t("Water Stress"),
            soil_ph_risk: t("Soil pH"),
            precipitation_risk: t("Precipitation"),
          };
          const topRisks = changeKeys(resultArray, keyMapping);
          console.log(topRisks, "toprisk");

          const firstThreeKeys = [];
          const firstThreeValues = [];

          // Loop over the first three objects
          for (let i = 0; i < 3; i++) {
            const obj = topRisks[i];
            for (const key in obj) {
              // Push key to firstThreeKeys if it's not already there
              if (!firstThreeKeys.includes(key)) {
                firstThreeKeys.push(key);
              }
              // Push value to firstThreeValues
              firstThreeValues.push(obj[key]);
            }
          }

          setTopThreeIndicis(firstThreeKeys);
          setTopThreeRisks([{ data: firstThreeValues }]);
          setRiskData(calculateResult.total_scores);
          setLocationScoreId(
            calculateResult.location_score_id.location_score_id
          );
          setClimateScores(calculateResult.total_scores.climate_scores);
          setIsComputed(true);
          console.log(
            data?.data?.total_scores.soil_scores?.composite_soil_risk,
            "datatest"
          );

          // Change the warning logic here -- 2024-08-05 --

          // composite total
          if (Math.floor(data?.data.total_scores.composite_total_risk) > 74) {
            Swal.fire({
              icon: "warning",
              title: t("The risk in the selected area is very high."),
              text: t("It is recommended not to proceed with this project."),
            });
            setIsLoading(false);
            return;
          }

          // climate and water
          else if (
            Math.floor(
              data?.data?.total_scores.climate_scores?.composite_climate_risk
            ) > 74 &&
            Math.floor(
              data?.data?.total_scores.water_scores?.composite_water_risk
            ) > 74
          ) {
            Swal.fire({
              icon: "warning",
              title: t("The climate and water score is too high"),
              text: t("It is recommended not to proceed with this project."),
            });
            setIsLoading(false);
            return;
          }

          // climate & soil
          else if (
            Math.floor(
              data?.data?.total_scores.climate_scores?.composite_climate_risk
            ) > 74 &&
            Math.floor(
              data?.data?.total_scores.soil_scores?.composite_soil_risk
            ) > 74
          ) {
            Swal.fire({
              icon: "warning",
              title: t("The climate and soil score is too high"),
              text: t("It is recommended not to proceed with this project."),
            });
            setIsLoading(false);
            return;
          }

          //climate
          else if (
            data?.data?.total_scores.climate_scores?.composite_climate_risk > 74
          ) {
            Swal.fire({
              icon: "warning",
              title: t("The climate score is too high"),
              text: t("It is recommended not to proceed with this project."),
            });
            setIsLoading(false);
          }

          //soil
          else if (
            Math.floor(
              data?.data?.total_scores.soil_scores?.composite_soil_risk
            ) > 74
          ) {
            Swal.fire({
              icon: "warning",
              title: "<h5 style='color:orange'>" + t("warning") + "</h5>",
              text: t(
                "The soil risk is too high! While it can be rehabilitated, it will require resources and time."
              ),
            });
            setIsLoading(false);
          }

          //water
          else if (
            Math.floor(
              data?.data?.total_scores.water_scores?.composite_water_risk
            ) > 74
          ) {
            Swal.fire({
              icon: "warning",
              title: "<h5 style='color:orange'>" + t("Warning.") + "</h5>",
              text: t(
                "The water risk is too high! While it can be rehabilitated, it will require resources and time."
              ),
            });
            setIsLoading(false);
          }
          setIsLoading(false);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // let parts = error.response.data.detail.split(":");
        console.log(error, "error");
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: t("Area Data Not Available"),
          // text: parts[1]?.trim(),
        });
      });
  };
  const handlelosePopUp = (e: any) => {
    if (e.target.id === "ModelContainer") {
      setOpenPopup(false);
    }
  };
  // useEffect(() => {
  //   if (scrollRef.current) {
  //     scrollRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [scrollRef.current]);
  // console.log(topThreeRisks[0].data[0], "iscomputed");
  const scrollToDiv = () => {
    if (scrollRef?.current) {
      // Scroll to the div using scrollIntoView
      scrollRef?.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleLanguageChange = (lang: any) => {
    console.log(`Language changed to: ${lang}`);
    // setLanguage(lang);
    i18n?.changeLanguage(lang);
  };
  return (
    <AuthenticatedTemplate>
      <MainDashboard>
        <div>
          {/* <BreadHeader
            title={`Hello ${firstName}`}
            description="Input data to calculate Risk"
          /> */}
          <h1 className="text-[25px] text-center md:text-left md:text-2xl font-bold mb-4">
            {t("Hello")} {firstName}, {t("Input data to calculate risk")}
          </h1>

          {/* Grid */}
          <div className="flex  flex-col md:grid md:grid-cols-2  lg:grid-cols-2  xl:flex xl:flex-row  gap-4">
            <div className=" w-[100%] relative xl:w-[28%] bg-[#ffffff] border border-gray-200 shadow-md rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7] ">
              <div className="py-4 px-7 flex flex-col ">
                <div className="">
                  <label
                    htmlFor="input-label"
                    className="block  text-[12px] text-[#404040] font-bold mb-1 dark:text-white"
                  >
                    {t("Location")}
                  </label>
                  {isLoaded && (
                    <>
                      <AutocompleteInput
                        setOpenPopup={setOpenPopup}
                        setGeoJson={setGeoJson}
                        value={`LatLng(${
                          selectedLocation?.lat || "Latitude"
                        }, ${selectedLocation?.lng || "Longitude"})`}
                        onLocationSelect={handleLocationSelect}
                        setFormValues={setFormValues}
                        formValues={formValues}
                        inputValueAuto={inputValueAuto}
                        setInputValueAuto={setInputValueAuto}
                      />
                    </>
                  )}
                  <div className="flex  gap-x-2 align-middle">
                    <div className="hs-tooltip inline-block [--placement:bottom]">
                      <p className="flex text-sm absolute top-2 right-2 z-20 text-gray-700 dark:text-gray-400 items-center gap-2">
                        {/* <i className="bi bi-exclamation-circle-fill mr-1"></i> */}
                        {isLocationProtected ? (
                          <i className="bi bi-exclamation-triangle-fill text-lg text-red-500 pr-3"></i>
                        ) : (
                          <i className="bi bi-patch-check-fill text-lg text-green-500"></i>
                        )}
                        <span className="text-[12px]">
                          {t("Biodiversity")}{" "}
                        </span>
                      </p>
                      <span
                        className="w-80 hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded-md shadow-sm dark:bg-slate-700"
                        role="tooltip"
                      >
                        {t(
                          "Biodiversity regions are geographically defined  protected areas that are effectively maintained through  legal or other ways to preserve biological diversity as  well as natural resources and related cultural  resources, such as forests and wildlife  sanctuaries.(JUCN 1994)"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="">
                  <label
                    htmlFor="hs-select-label"
                    className="block  text-[12px] text-[#404040] font-bold mb-1 dark:text-white"
                  >
                    {t("Loan Period (Months)")}
                  </label>
                  <input
                    type="number"
                    id="input-label"
                    value={`${toMonths}`}
                    onChange={handleChangeMonth}
                    onBlur={handleBlur}
                    // data-hs-overlay="#hs-focus-datepicker-modal"
                    className="h-10 py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                    placeholder="Date Period in Months"
                  />
                  <p className="text-gray-500 mt-1 mb-4 text-xs pt-2">{`${fromDate} to ${toDate}`}</p>
                </div>
                <div
                  className="mb-4
                "
                >
                  <label
                    htmlFor="hs-select-label"
                    className="block  text-[12px]  font-bold text-[#404040] mb-1 dark:text-white"
                  >
                    {t("Select a Crop")}:
                  </label>
                  <select
                    className="h-10 py-2 px-4 pr-9 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                    onChange={handleCropSelect}
                    value={selectedCrop}
                  >
                    <option value="">{t("Select a Crop")}</option>
                    {allCrops?.map((option) => (
                      <option key={option.name} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className="mb-4
                "
                >
                  <label
                    htmlFor="hs-select-label"
                    className="block  text-[12px]  font-bold text-[#404040] mb-1 dark:text-white"
                  >
                    {t("Select Water Source")}:
                  </label>
                  <select
                    className="h-10 py-2 px-4 pr-9 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                    onChange={(e) => {
                      setSource(e.target.value);
                    }}
                    value={source}
                  >
                    <option value="">{t("Select Water Source")}</option>
                    {waterSource?.map((option) => (
                      <option key={option.name} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex justify-center items-center">
                  <ButtonLoading
                    onClick={onSubmit}
                    isLoading={isLoading}
                    disabled={isLocationProtected}
                    text="Compute Score"
                  >
                    {t("Compute Score")}
                  </ButtonLoading>
                </div>
              </div>
              <div
                id="hs-bg-gray-on-hover-cards"
                className="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto"
              >
                <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all lg:max-w-4xl lg:w-full m-3 lg:mx-auto">
                  <div className="flex flex-col bg-white border shadow-sm rounded-xl ">
                    <div className="flex flex-col-reverse md:flex-row justify-between items-center py-3 px-4 border-b ">
                      <h3 className="font-bold text-gray-800 ">
                        {t("Detailed Risk Report Analysis")}
                      </h3>
                      <div>
                        {/* Col */}
                        <div className="inline-flex w-100 justify-between gap-x-2 mr-5">
                          <button
                            className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm  "
                            onClick={handleDownloadPDF}
                          >
                            <i className="bi bi-download"></i>
                            {t("PDF")}
                          </button>
                          <button
                            className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm "
                            onClick={handlePrint}
                          >
                            <i className="bi bi-printer-fill"></i>
                            {t("PRINT")}
                          </button>
                        </div>
                        {/* Col */}
                        <button
                          type="button"
                          className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm "
                          data-hs-overlay="#hs-bg-gray-on-hover-cards"
                        >
                          <span className="sr-only"> {t("Close")}</span>
                          <svg
                            className="w-3.5 h-3.5"
                            width={8}
                            height={8}
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="p-4 overflow-y-auto">
                      <PdfComponent
                        myRiskdata={riskData}
                        loanPeriod={toMonths}
                        crop={formValues.crop}
                        myLocation={selectedLocation}
                        locationScoreId={locationScoreId}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="ModelContainer"
              onClick={handlelosePopUp}
              className={` ${
                openPopup === true ? "map-bg" : "map-bg-none"
              }  p-4  w-[100%] md:h-auto xl:w-[42%]  bg-white border rounded-xl   border-gray-200 shadow-sm  dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7] bg-card`}
            >
              <div className="map-h-s md:h-full  " style={{ width: "" }}>
                <div
                  onClick={() => setOpenPopup(false)}
                  className="input-m absolute right-2 top-2 "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </div>
                <div className="input-m mt-4">
                  {/* <label
                    htmlFor="input-label"
                    className="block  text-sm text-[#404040] font-bold mb-1 dark:text-white"
                  >
                    Location
                  </label> */}
                  {isLoaded && (
                    <>
                      <AutocompleteInput
                        setGeoJson={setGeoJson}
                        value={`LatLng(${
                          selectedLocation?.lat || "Latitude"
                        }, ${selectedLocation?.lng || "Longitude"})`}
                        onLocationSelect={handleLocationSelect}
                        setFormValues={setFormValues}
                        formValues={formValues}
                        inputValueAuto={inputValueAuto}
                        setInputValueAuto={setInputValueAuto}
                      />
                    </>
                  )}
                </div>
                {/* <div ref={elementRef}>
                {isLoaded && (
                  <MapComponent
                    geoJson={geoJson}
                    isLocationProtected
                    mapLocation={selectedLocation}
                    onMarkerPlaced={handleMarkerPlaced}
                    onMarkerClear={handleMarkerClear}
                  />
                )}
              </div> */}
                <Places
                  setIsLocationProtected={setIsLocationProtected}
                  geoJson={geoJson}
                  setSelectedLocation={setSelectedLocation}
                  selectedLocation={selectedLocation}
                />
              </div>
            </div>
            <div ref={scrollRef} className="w-[100%] xl:w-[32%] col-span-2 ">
              {isComputed ? (
                <div className=" min-h-[400px]  bg-card text-card-foreground bg-[#ffffff] border border-gray-200 shadow-md rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
                  <div className=" p-6 flex justify-between flex-col ">
                    <div className="flex flex-col justify-between gap-2 md:gap-[12] lg:gap-[60px]">
                      <h3 className="font-extrabold text-base leading-none tracking-tight">
                        {t("Composite Risk Score")}
                      </h3>

                      <div className="md:flex  items-center justify-around ">
                        <Gauge level={riskData?.composite_total_risk} />

                        <div className="">
                          <div className="lg:block mt-5 ">
                            <div className="flex items-start flex-col w-100 md:w-[148px]">
                              <h1 className="font-semibold text-sm leading-none tracking-tight items-start mb-3">
                                {t("Drivers of Risk")}
                              </h1>
                              <div className="w-[100%]">
                                <div className="text-xs p-1 text-[#404040] dark:text-[#FFFFF7]">
                                  {topThreeIndicis[0]}
                                </div>
                                {/* <div class="w-[170px] h-6 bg-gray-200 rounded-full dark:bg-gray-700">
                                  <div
                                    class="h-6   rounded-full dark:bg-blue-500"
                                    style={{
                                      backgroundColor: `${pickColor(
                                        topThreeRisks[0].data[0]
                                      )}`,
                                      width: `${topThreeRisks[0].data[0]}%`,
                                    }}
                                  ></div>
                                </div> */}

                                <ProgressBarHome
                                  category={topThreeIndicis[0]}
                                  idle={true}
                                  score={topThreeRisks[0].data[0]}
                                />
                              </div>
                              <div className="w-[100%]">
                                <div className="text-xs p-1 text-[#404040] dark:text-[#FFFFF7]">
                                  {topThreeIndicis[1]}
                                </div>
                                {/* <div class="w-[170px] h-6 bg-gray-200 rounded-full dark:bg-gray-700">
                                  <div
                                    class="h-6 rounded-full dark:bg-blue-500"
                                    style={{
                                      backgroundColor: `${pickColor(
                                        topThreeRisks[0].data[1]
                                      )}`,
                                      width: `${topThreeRisks[0].data[1]}%`,
                                    }}
                                  ></div>
                                </div> */}
                                <ProgressBarHome
                                  category={topThreeIndicis[1]}
                                  idle={true}
                                  score={topThreeRisks[0].data[1]}
                                />
                              </div>
                              <div className="w-[100%]">
                                <div className="text-xs p-1 text-[#404040] dark:text-[#FFFFF7]">
                                  {topThreeIndicis[2]}
                                </div>
                                {/* <div class="w-[170px] h-6 bg-gray-200 rounded-full dark:bg-gray-700">
                                  <div
                                    class="h-6  rounded-full dark:bg-blue-500"
                                    style={{
                                      backgroundColor: `${pickColor(
                                        topThreeRisks[0].data[2]
                                      )}`,
                                      width: `${topThreeRisks[0].data[2]}%`,
                                    }}
                                  ></div>
                                </div> */}
                                <ProgressBarHome
                                  category={topThreeIndicis[2]}
                                  idle={true}
                                  score={topThreeRisks[0].data[2]}
                                />
                              </div>
                            </div>
                            {/* <div className="flex justify-between items-center gap-x-4 px-8  ">
                          <div>
                            <GradientBarChart
                              composite={true}
                              categories={topThreeIndicis}
                              scores={topThreeRisks}
                            />
                          </div>
                        </div> */}
                          </div>
                          {/* <h2 className="font-semibold text-sm leading-none tracking-tight mt-2">
                      CLIMATE ADAPTATION PLAN
                    </h2> */}

                          {/* <p className="text-sm mt-3">
                      {riskData?.adaptations[1].Suggestion}
                    </p> */}
                        </div>
                      </div>
                      <button
                        className="h-10 mt-4 py-3 px-4 inline-flex justify-center w-full items-center gap-2 rounded-md border border-transparent font-semibold bg-[#58BC61] text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                        data-hs-overlay="#hs-bg-gray-on-hover-cards"
                        onClick={handleReportClick}
                      >
                        {t("View Full Report")}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="min-h-[430px] bg-card text-card-foreground bg-white border border-gray-400 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7] col-span-2">
                    <div className="flex flex-col gap-3 p-6">
                      <h3 className="font-semibold text-sm leading-none tracking-tight">
                        {t("Composite Risk Score")}
                      </h3>
                      <IdleGauge />

                      <div>
                        <h3
                          className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mt-8 "
                          style={{ width: "40%" }}
                        />
                        <div className="lg:block hidden mt-10"></div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-7  mt-5">
            <TopGauge
              composite={false}
              idle={isComputed}
              pillar={t("CLIMATE")}
              rainfall_risk={riskData?.climate_scores.drought_risk}
              temperature_risk={riskData?.climate_scores.precipitation_risk}
              drought_risk={riskData?.climate_scores.temperature_risk}
              composite_climate_risk={
                riskData?.climate_scores.composite_climate_risk
              }
              categories={climate_indices}
              tooltip={scoreTooltipData[0].tip}
            />
            <TopGauge
              idle={isComputed}
              composite={false}
              pillar={t("WATER")}
              rainfall_risk={riskData?.water_scores.ground_water_risk}
              temperature_risk={riskData?.water_scores.rainfall_erosivity_risk}
              drought_risk={riskData?.water_scores.location_aquaduct_risk}
              composite_climate_risk={
                riskData?.water_scores.composite_water_risk
              }
              categories={water_indices}
              tooltip={scoreTooltipData[1].tip}
            />
            <TopGauge
              idle={isComputed}
              composite={false}
              pillar={t("SOIL")}
              rainfall_risk={riskData?.soil_scores.soil_organic_carbon_risk}
              temperature_risk={riskData?.soil_scores.soil_ph_risk}
              drought_risk={riskData?.soil_scores.cation_exchange_capacity_risk}
              composite_climate_risk={riskData?.soil_scores.composite_soil_risk}
              categories={soil_indices}
              tooltip={scoreTooltipData[2].tip}
            />
          </div>
          {/* End Grid */}
          <NewsFeed />
        </div>
      </MainDashboard>
    </AuthenticatedTemplate>
  );
};
export default Home;
